import { lazy, Suspense } from "react";
import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { Layout as AuthModernLayout } from "src/layouts/auth/modern-layout";
import { Layout as AuthDummyLayout } from "src/layouts/auth/dummy-layout";
import { paths } from "src/paths";
import { canAccessPage } from "src/utils/permissions";
import { getCachedUser } from "src/utils/cache";
import NotFound from "src/pages/error/404";
import { SplashScreen } from "src/components/splash-screen";
// import Header from "src/layouts/dashboard/app-layout";

const ExamResultSearch = lazy(() => import("src/pages/public/exam-result/search"));
const StudentResultTable = lazy(() => import("src/pages/examinations/studentResultTable"));
const ViewExamResults = lazy(() => import("src/pages/exam-results"));
const ResultDetails = lazy(() => import("src/pages/exam-results/resultDetail"));
const EditResult = lazy(() => import("src/pages/examinations/editResultTable"));
const PayTeacherSalary = lazy(() => import("src/pages/teachers/paySalary"));
const ActionRequests = lazy(() => import("src/pages/action-requests"));
const Activity = lazy(() => import("src/pages/activity"));
const CreateTenant = lazy(() => import("src/pages/tenant/create"));
const Tenants = lazy(() => import("src/pages/tenant"));
const StudentEdit = lazy(() => import("src/pages/students/edit"));
const SchoolSettings = lazy(() => import("src/pages/school"));
const EditSchoolSettings = lazy(() => import("src/pages/school/edit"));
const UpdateThemeSettings = lazy(() => import("src/pages/school/update-theme-setting"));
const Profile = lazy(() => import("src/pages/profile"));
const ProfileEdit = lazy(() => import("src/pages/profileEdit"));
const TeacherProfileEdit = lazy(() => import("src/pages/teachers/profileEdit"));
const Permissions = lazy(() => import("src/pages/permissions"));
const AddTransaction = lazy(() => import("src/pages/finance/addTransaction"));
const EditTransaction = lazy(() => import("src/pages/finance/addTransaction"));
const AddExam = lazy(() => import("src/pages/examinations/add"));
const EditExam = lazy(() => import("src/pages/examinations/edit"));
const ExaminationDetails = lazy(() => import("src/pages/examinations/detail"));
const Subjects = lazy(() => import("src/pages/subjects"));
const SocialMediaAccounts = lazy(() => import("src/pages/social-media-accounts"));
const SocialInstagram = lazy(() => import("src/pages/social-instagram"));
const SocialFacebook = lazy(() => import("src/pages/socail-facebook"));
const SocialLinkedIn = lazy(() => import("src/pages/social-linkedin"));
const AddSubject = lazy(() => import("src/pages/subjects/add"));
const EditSubject = lazy(() => import("src/pages/subjects/edit"));
const AddExamSchedule = lazy(() => import("src/pages/examinations/addSchedule"));
const PreviewExamSchedule = lazy(() => import("src/pages/examinations/previewExamSchedule"));
const CreateQuestionPaper = lazy(() =>
  import("src/pages/examinations/question-paper/createQuestionPaper")
);
const QuestionPaper = lazy(() => import("src/pages/examinations/question-paper/questionPaper"));
const EditQuestionPaper = lazy(() =>
  import("src/pages/examinations/question-paper/editQuestionPaper")
);

const Home = lazy(() => import("src/pages/landing-page/index"));

const OnBoard = lazy(() => import("src/pages/on-boarding"));
const OnBoarding = lazy(() => import("src/pages/on-boarding/on-boarding"));

const About = lazy(() => import("src/pages/landing-page/about"));
const LiveResult = lazy(() => import("src/pages/live-result"));
const DashBoard = lazy(() => import("src/pages/index"));
const HelpAndSupport = lazy(() => import("src/pages/help-support"));
const MenuPage = lazy(() => import("src/pages/menu"));
const LogInPage = lazy(() => import("src/pages/logIn"));
const SignUpPage = lazy(() => import("src/pages/signUp"));
const ResetPassword = lazy(() => import("src/pages/users/resetPassword"));
const PrivacyPolicy = lazy(() => import("src/pages/terms-n-conditions/privacy-policy"));
const Grades = lazy(() => import("src/pages/grades/index"));
const AddUser = lazy(() => import("src/pages/users/addUser"));
const AddEvent = lazy(() => import("src/pages/event/event-form"));
const EditEvent = lazy(() => import("src/pages/event/event-form"));
const Users = lazy(() => import("src/pages/users/"));
const ViewUser = lazy(() => import("src/pages/users/profile"));
const EditUser = lazy(() => import("src/pages/users/profileEdit"));
const AddNewGrade = lazy(() => import("src/pages/grades/add"));
const EditGrade = lazy(() => import("src/pages/grades/edit"));
const ViewGrade = lazy(() => import("src/pages/grades/view"));
const GradesSections = lazy(() => import("src/pages/grades/sections"));
const ViewGradeSection = lazy(() => import("src/pages/grades/sections/view"));
const AssignTeacher = lazy(() => import("src/pages/grades/sections/assign-teacher"));
const Students = lazy(() => import("src/pages/students/index"));
const DailySummary = lazy(() => import("src/pages/admin-summary"));
const Courses = lazy(() => import("src/pages/study-material/index"));
const ViewEnrollCourse = lazy(() => import("src/pages/study-material/chapters"));
const StudentDetails = lazy(() => import("src/pages/students/detail"));
const AddStudent = lazy(() => import("src/pages/students/add"));
const StudentDistribution = lazy(() => import("src/pages/students/distribution"));
const StudentTable = lazy(() => import("src/pages/students/student-table"));
const StudentDraft = lazy(() => import("src/pages/students/student-draft"));
const StudentDynamicField = lazy(() => import("src/pages/students/student-dynamic-field"));
const AddStudentDynamicField = lazy(() =>
  import("src/pages/students/student-dynamic-field/dynamic-field-form")
);
const EditStudentDynamicField = lazy(() =>
  import("src/pages/students/student-dynamic-field/dynamic-field-form")
);
const AddStudentDraft = lazy(() => import("src/pages/students/student-draft/draft-form"));
const EditStudentDraft = lazy(() => import("src/pages/students/student-draft/draft-form"));
const ImportSummaries = lazy(() => import("src/pages/students/import-summary"));
const ImportSummariesDetail = lazy(() => import("src/pages/students/import-summary/detail"));
const StudentDataFormatter = lazy(() => import("src/pages/students/data-formatter"));
const StudentGenerateID = lazy(() => import("src/pages/students/generate-id"));
const TakeGradeSectionAttendance = lazy(() => import("src/pages/grades/sections/take-attendance"));

const Exams = lazy(() => import("src/pages/examinations"));
const Adminstration = lazy(() => import("src/pages/adminstration"));
const FileManagement = lazy(() => import("src/components/file-management/index"));
const WebsiteManagement = lazy(() => import("src/pages/website/index"));
const AllAnnouncements = lazy(() => import("src/pages/landing-page/all-announcements"));

const AddPublicPost = lazy(() => import("src/pages/website/public-post/post-form"));
const EditPublicPost = lazy(() => import("src/pages/website/public-post/post-form"));
const ViewPublicPost = lazy(() => import("src/pages/view-post"));

const AddAnnouncement = lazy(() => import("src/components/announcement/announcement-form"));
const EditAnnouncement = lazy(() => import("src/components/announcement/announcement-form"));
const EditSliderContent = lazy(() => import("src/pages/website/slider-section/edit"));
const AddAdmission = lazy(() => import("src/pages/admission/admission-from"));
const FeeStructure = lazy(() => import("src/pages/finance/fee-structure"));
const AddFeeStructure = lazy(() => import("src/pages/finance/fee-structure/add"));
const ViewFeeStructure = lazy(() => import("src/pages/finance/fee-structure/view"));
const FeeTags = lazy(() => import("src/pages/finance/fee-tags"));
const EditFeeTags = lazy(() => import("src/pages/finance/fee-tags/fee-tag-form"));
const AddFeeTags = lazy(() => import("src/pages/finance/fee-tags/fee-tag-form"));
const EditFeeStructure = lazy(() => import("src/pages/finance/fee-structure/edit"));
const StudentPreviousYearFees = lazy(() => import("src/pages/students/previous-year-fee"));
const AddStudentPreviousYearFees = lazy(() =>
  import("src/pages/students/previous-year-fee/previous-year-fee-form")
);
const EditStudentPreviousYearFees = lazy(() =>
  import("src/pages/students/previous-year-fee/previous-year-fee-form")
);
const Finance = lazy(() => import("src/pages/finance/index"));
const Accounts = lazy(() => import("src/pages/finance/finance-account"));
const BalanceSheet = lazy(() => import("src/pages/finance/finance-account/balance-sheet"));
const AddNewAccount = lazy(() => import("src/pages/finance/finance-account/account-form"));
const EditAccount = lazy(() => import("src/pages/finance/finance-account/account-form"));
const Teachers = lazy(() => import("src/pages/teachers/index"));
const AddTeacher = lazy(() => import("src/pages/teachers/add"));
const TeacherDraft = lazy(() => import("src/pages/teachers/teacher-draft"));
const AddTeacherDraft = lazy(() => import("src/pages/teachers/teacher-draft/draft-form"));
const EditTeacherDraft = lazy(() => import("src/pages/teachers/teacher-draft/draft-form"));
const TeacherAttendance = lazy(() => import("src/pages/teachers/teacher-attendance"));
const Attendance = lazy(() => import("src/pages/attendance/index"));
const AttendanceReport = lazy(() => import("src/pages/attendance/attendanceReport"));
const AttendanceDistributionReport = lazy(() =>
  import("src/pages/attendance/attandanceDistributionReport")
);
const GradeAttendanceDetails = lazy(() => import("src/pages/attendance/view"));
const TeacherProfile = lazy(() => import("src/pages/teachers/profile"));
const DummyLoggedIn = lazy(() => import("src/pages/public/dummy-loggedin"));
const ParentDashBoard = lazy(() => import("src/pages/parents/dashbaord"));
const StudentFee = lazy(() => import("src/pages/parents/student-fees"));
const StudentAttandance = lazy(() => import("src/pages/parents/attandance"));
const StudentProfileView = lazy(() => import("src/pages/parents/student"));
const ParentNotifications = lazy(() => import("src/pages/parents/notifications"));

const StudentDashBoard = lazy(() => import("src/pages/students-user/dashbaord"));
const StudentFeesView = lazy(() => import("src/pages/students-user/student-fees"));
const StudentAttandanceView = lazy(() => import("src/pages/students-user/attandance"));

// App.learnPlace.in Routes
const AppHome = lazy(() => import("src/pages/app-learnplace/index"));
const AppLogin = lazy(() => import("src/pages/app-learnplace/login"));
const GoogleCallBack = lazy(() => import("src/pages/app-learnplace/callback"));
const AppSignUp = lazy(() => import("src/pages/app-learnplace/signup"));
const AppHomePage = lazy(() => import("src/pages/app-learnplace/home-page"));
const VoiceAssistant = lazy(() => import("src/pages/VoiceAssistant"));

const cachedUser = getCachedUser();

export const publicRoutes = [
  {
    index: true,
    path: paths.index,
    element: <Home />,
  },
  {
    index: true,
    path: paths.appIndex,
    element: <AppHome />,
  },
  {
    path: paths.onBoard,
    element: <OnBoard />,
  },
  {
    path: paths.onBoarding(":id"),
    element: <OnBoarding />,
  },
  {
    path: paths.addStudentDraft,
    element: <AddStudentDraft />,
  },
  {
    path: paths.editStudentDraft(":id"),
    element: <EditStudentDraft />,
  },
  {
    path: paths.addTeacherDraft,
    element: <AddTeacherDraft />,
  },
  {
    path: paths.editTeacherDraft(":id"),
    element: <EditTeacherDraft />,
  },
  {
    path: paths.about,
    element: <About />,
  },
  {
    path: paths.liveResult,
    element: <LiveResult />,
  },
  {
    path: paths.notice,
    element: <AllAnnouncements />,
  },
  {
    path: paths.addAdmission,
    element: <AddAdmission />,
  },
  {
    path: paths.examResultSearch,
    element: <ExamResultSearch />,
  },
  {
    path: paths.viewPost(":id"),
    element: <ViewPublicPost />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/splash-screen",
    element: <SplashScreen />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: paths.socialInstagram,
    element: <SocialInstagram />,
  },
  {
    path: paths.socialFacebook,
    element: <SocialFacebook />,
  },
  {
    path: paths.socialLinkedIn,
    element: <SocialLinkedIn />,
  },
];

export const privateRoutes = [
  ...[
    {
      path: paths.dashboard,
      element: <DashBoard />,
    },
    {
      path: paths.helpAndSupport,
      element: <HelpAndSupport />,
    },
    {
      path: paths.sections,
      element: <GradesSections />,
    },
    {
      path: paths.assignClassSectionTeacher,
      element: <AssignTeacher />,
    },
    {
      path: paths.viewClassSection(":id"),
      element: <ViewGradeSection />,
    },
    {
      path: paths.takeClassSectionAttendance(":id"),
      element: <TakeGradeSectionAttendance />,
    },
    {
      path: paths.finance,
      element: <Finance />,
    },
    {
      path: paths.addFee,
      element: <AddFeeStructure />,
    },
    {
      path: paths.viewFeeStructure(":id"),
      element: <ViewFeeStructure />,
    },
    {
      path: paths.feeTags,
      element: <FeeTags />,
    },
    {
      path: paths.addFeeTags,
      element: <AddFeeTags />,
    },
    {
      path: paths.editFeeTags(":id"),
      element: <EditFeeTags />,
    },
    {
      path: paths.editFee(":id"),
      element: <EditFeeStructure />,
    },
    {
      path: paths.previousYearFees,
      element: <StudentPreviousYearFees />,
    },
    {
      path: paths.addPreviousYearFees,
      element: <AddStudentPreviousYearFees />,
    },
    {
      path: paths.editPreviousYearFees(":id"),
      element: <EditStudentPreviousYearFees />,
    },
    {
      path: paths.feeStructure,
      element: <FeeStructure />,
    },
    {
      path: paths.classes,
      element: <Grades />,
    },
    {
      path: paths.addEvent,
      element: <AddEvent />,
    },
    {
      path: paths.editEvent(":id"),
      element: <EditEvent />,
    },
    {
      path: paths.addUser,
      element: <AddUser />,
    },
    {
      path: paths.users,
      element: <Users />,
    },
    {
      path: paths.viewUser(":id"),
      element: <ViewUser />,
    },
    {
      path: paths.editUser(":id"),
      element: <EditUser />,
    },
    {
      path: paths.addClass,
      element: <AddNewGrade />,
    },
    {
      path: paths.accounts,
      element: <Accounts />,
    },
    {
      path: paths.editAccount(":id"),
      element: <EditAccount />,
    },
    {
      path: paths.addAccount,
      element: <AddNewAccount />,
    },
    {
      path: paths.balanceSheet,
      element: <BalanceSheet />,
    },
    {
      path: paths.viewClass(":id"),
      element: <ViewGrade />,
    },
    {
      path: paths.editClass(":id"),
      element: <EditGrade />,
    },
    {
      path: paths.dailySummary,
      element: <DailySummary />,
    },
    {
      path: paths.courses,
      element: <Courses />,
    },
    {
      path: paths.viewEnrollCourse(":id", ":enrollId"),
      element: <ViewEnrollCourse />,
    },

    {
      path: paths.students,
      element: <Students />,
    },
    {
      path: paths.addStudent,
      element: <AddStudent />,
    },
    {
      path: paths.studentDistribution,
      element: <StudentDistribution />,
    },
    {
      path: paths.studentTable,
      element: <StudentTable />,
    },
    {
      path: paths.studentDraft,
      element: <StudentDraft />,
    },
    {
      path: paths.studentDynamicField,
      element: <StudentDynamicField />,
    },
    {
      path: paths.addStudentDynamicField,
      element: <AddStudentDynamicField />,
    },
    {
      path: paths.editStudentDynamicFieldForm(":id"),
      element: <EditStudentDynamicField />,
    },
    {
      path: paths.permissions(":id"),
      element: <Permissions />,
    },
    {
      path: paths.viewStudent(":id"),
      element: <StudentDetails />,
    },
    {
      path: paths.teachers,
      element: <Teachers />,
    },
    {
      path: paths.viewTeacher(":id"),
      element: <TeacherProfile />,
    },
    {
      path: paths.addTeacher,
      element: <AddTeacher />,
    },
    {
      path: paths.teacherDraft,
      element: <TeacherDraft />,
    },
    {
      path: paths.teacherAttendance,
      element: <TeacherAttendance />,
    },
    {
      path: paths.adminstration,
      element: <Adminstration />,
    },

    {
      path: paths.addPost,
      element: <AddPublicPost />,
    },
    {
      path: paths.editPost(":id"),
      element: <EditPublicPost />,
    },

    {
      path: paths.fileManagement,
      element: <FileManagement />,
    },
    {
      path: paths.websiteManagement,
      element: <WebsiteManagement />,
    },
    {
      path: paths.addAnnouncement,
      element: <AddAnnouncement />,
    },
    {
      path: paths.editAnnouncement(":id"),
      element: <EditAnnouncement />,
    },
    {
      path: paths.editSliderContent(":id"),
      element: <EditSliderContent />,
    },
    {
      path: paths.attendance,
      element: <Attendance />,
    },
    {
      path: paths.attendanceReport,
      element: <AttendanceReport />,
    },
    {
      path: paths.attendanceDistributionReport,
      element: <AttendanceDistributionReport />,
    },
    {
      path: paths.viewAttendance(":id"),
      element: <GradeAttendanceDetails />,
    },
    {
      path: paths.studentImportSummaries,
      element: <ImportSummaries />,
    },
    {
      path: paths.studentDataFormatter,
      element: <StudentDataFormatter />,
    },
    {
      path: paths.studentGenerateID,
      element: <StudentGenerateID />,
    },
    {
      path: paths.studentImportSummary(":id"),
      element: <ImportSummariesDetail />,
    },
    {
      path: paths.examinations,
      element: <Exams />,
    },
    {
      path: paths.profile,
      element: <Profile />,
    },

    {
      path: paths.profileEdit,
      element: <ProfileEdit />,
    },
    {
      path: paths.editTeacher(":id"),
      element: <TeacherProfileEdit />,
    },
    {
      path: paths.addFinance,
      element: <AddTransaction />,
    },
    {
      path: paths.editFinance(":id"),
      element: <EditTransaction />,
    },
    {
      path: paths.addExam,
      element: <AddExam />,
    },
    {
      path: paths.editExam(":id"),
      element: <EditExam />,
    },
    {
      path: paths.viewExam(":id"),
      element: <ExaminationDetails />,
    },
    {
      path: paths.subjects,
      element: <Subjects />,
    },
    {
      path: paths.socialMediaAccounts,
      element: <SocialMediaAccounts />,
    },

    {
      path: paths.addSubject,
      element: <AddSubject />,
    },
    {
      path: paths.editSubject(":id"),
      element: <EditSubject />,
    },
    {
      path: paths.addExamSchedule(":id"),
      element: <AddExamSchedule />,
    },
    {
      path: paths.createQuestionPaper,
      element: <CreateQuestionPaper />,
    },
    {
      path: paths.questionPaper,
      element: <QuestionPaper />,
    },
    {
      path: paths.editQuestionPaper(":id"),
      element: <EditQuestionPaper />,
    },
    {
      path: paths.previewExamSchedule(":id"),
      element: <PreviewExamSchedule />,
    },
    {
      path: paths.studentResultTable(":id"),
      element: <StudentResultTable />,
    },
    {
      path: paths.viewExamResults(":id"),
      element: <ViewExamResults />,
    },
    {
      path: paths.viewExamResultsDetail(":id"),
      element: <ResultDetails />,
    },
    {
      path: paths.editResult,
      element: <EditResult />,
    },
    {
      path: paths.payTeacherSalary(":id"),
      element: <PayTeacherSalary />,
    },
    {
      path: paths.activity,
      element: <Activity />,
    },
    {
      path: paths.actionRequests,
      element: <ActionRequests />,
    },
    {
      path: paths.createTenant,
      element: <CreateTenant />,
    },
    {
      path: paths.tenants,
      element: <Tenants />,
    },
    {
      path: paths.editStudent(":id"),
      element: <StudentEdit />,
    },
    {
      path: paths.schoolSettings,
      element: <SchoolSettings />,
    },
    {
      path: paths.editSchoolSettings,
      element: <EditSchoolSettings />,
    },
    {
      path: paths.updateThemeSettings,
      element: <UpdateThemeSettings />,
    },
    {
      path: paths.homeMenu,
      element: <MenuPage />,
    },
    {
      path: paths.parentDashBoard,
      element: <ParentDashBoard />,
    },
    {
      path: paths.studentFee,
      element: <StudentFee />,
    },
    {
      path: paths.studentAttandance,
      element: <StudentAttandance />,
    },
    {
      path: paths.studentProfileView(":id"),
      element: <StudentProfileView />,
    },
    {
      path: paths.parentNotifications,
      element: <ParentNotifications />,
    },
    {
      path: paths.studentDashBoard,
      element: <StudentDashBoard />,
    },
    {
      path: paths.studentFeesView,
      element: <StudentFeesView />,
    },
    {
      path: paths.studentAttendanceView,
      element: <StudentAttandanceView />,
    },
    {
      path: paths.chatBot,
      element: <VoiceAssistant />,
    },

    // {
    //   path: paths.admissionQuery,
    //   element: <AdmissionQuery />,
    // },
    // {
    //   path: paths.publicPost,
    //   element: <PublicPost />,
    // },
    // {
    //   path: paths.gallery,
    //   element: <Gallery />,
    // },
    // {
    //   path: paths.announcement,
    //   element: <Announcement />,
    // },
  ]
    .filter((item) => canAccessPage(cachedUser, item.path))
    .map((item) => ({
      ...item,
      element: (
        <DashboardLayout>
          <Suspense>{item.element}</Suspense>
        </DashboardLayout>
      ),
    })),
  ...publicRoutes,
];

export const globalRoutes = [
  ...[
    {
      path: paths.appHome,
      element: <AppHomePage />,
    },
  ]
    .filter((item) => canAccessPage(cachedUser, item.path))
    .map((item) => ({
      ...item,
      element: (
        <DashboardLayout>
          <Suspense>{item.element}</Suspense>
        </DashboardLayout>
      ),
    })),
  ...publicRoutes,
];

export const authRoutes = [
  {
    path: paths.login,
    element: (
      <AuthModernLayout>
        <LogInPage />
      </AuthModernLayout>
    ),
  },
  {
    path: paths.signUp,
    element: (
      <AuthModernLayout>
        <SignUpPage />
      </AuthModernLayout>
    ),
  },
  {
    index: true,
    path: paths.appLogin,
    element: (
      <AuthModernLayout>
        <AppLogin />
      </AuthModernLayout>
    ),
  },
  {
    index: true,
    path: paths.googleCallback,
    element: <GoogleCallBack />,
  },
  {
    index: true,
    path: paths.appSignup,
    element: (
      <AuthModernLayout>
        <AppSignUp />
      </AuthModernLayout>
    ),
  },
  {
    path: paths.resetPassword,
    element: (
      <AuthModernLayout>
        <ResetPassword />
      </AuthModernLayout>
    ),
  },
  {
    path: "/dummy-logged-in",
    element: (
      <AuthDummyLayout>
        <DummyLoggedIn />
      </AuthDummyLayout>
    ),
  },
];
