import { appConfig } from "src/config";
import {
  reqAnonGet,
  reqAnonPost,
  reqGet,
  reqPatch,
  reqPost,
  getAuthHeaders,
  reqDelete,
} from "../utils/requests";

const hostname = window.location.hostname;
const tenantHostPrefix = hostname.split(".")[0];
const apiBaseUrl = `${appConfig.baseApiUrl}/${tenantHostPrefix}/api/v1`;

const apiCalls = {
  googleLogin: async (params = {}, data) =>
    await reqAnonGet(`${apiBaseUrl}/app-sign-in-google`, params, data),
  loginWithGoogle: async (data) =>
    await reqAnonGet(`${apiBaseUrl}/app-sign-in-google-callback`, data),

  login: async (data) => await reqAnonPost(`${apiBaseUrl}/sign-in/`, data),
  signup: async (data) => await reqAnonPost(`${apiBaseUrl}/sign-up/`, data),
  AppLogin: async (data) => await reqAnonPost(`${apiBaseUrl}/app-sign-in/`, data),
  AppSignup: async (data) => await reqAnonPost(`${apiBaseUrl}/app-sign-up/`, data),
  thisTenant: async () => await reqAnonGet(`${apiBaseUrl}/tenants/current/`),
  currentUser: async () => await reqGet(`${apiBaseUrl}/users/current/`),
  AppcurrentUser: async () => await reqGet(`${apiBaseUrl}/app_users/current/`),
  verifyPassword: async (data) => await reqPost(`${apiBaseUrl}/users/verify-password/`, data),
  updatePassword: async (data) => await reqPost(`${apiBaseUrl}/users/update-password/`, data),
  resetPassword: async (data) => await reqAnonPost(`${apiBaseUrl}/users/reset-password/`, data),
  sendOtp: async (data) => await reqAnonPost(`${apiBaseUrl}/otp-verifications/send-otp/`, data),
  verifyOtp: async (data) => await reqAnonPost(`${apiBaseUrl}/otp-verifications/verify-otp/`, data),
  createOnBoarding: async (data) => await reqAnonPost(`${apiBaseUrl}/on-boarding/`, data),
  getOnBoarding: async () => await reqAnonGet(`${apiBaseUrl}/on-boarding/`),
  getOnBoardingDetails: async (id) => await reqAnonGet(`${apiBaseUrl}/on-boarding/${id}/`),
  updateOnBoardingVerification: async (data) =>
    await reqAnonPost(`${apiBaseUrl}/on-boarding/verify-phone-email/`, data),
  updateOnBoardingSchoolDetails: async (data) =>
    await reqAnonPost(`${apiBaseUrl}/on-boarding/school-details/`, data),
  getOnBoardingUserDetails: async (params) =>
    await reqAnonGet(`${apiBaseUrl}/on-boarding/user-details/`, params),
  updateOnBoardingUserDetails: async (data) =>
    await reqAnonPost(`${apiBaseUrl}/on-boarding/user-details/`, data),
  updateOnClassCreation: async (data) =>
    await reqAnonPost(`${apiBaseUrl}/on-boarding/class-creation/`, data),
  sendEmailOtp: async (data) =>
    await reqAnonPost(`${apiBaseUrl}/email-verifications/send-email-otp/`, data),
  verifyEmailOtp: async (data) =>
    await reqAnonPost(`${apiBaseUrl}/email-verifications/verify-email-otp/`, data),
  getDashboardStats: async (params) => await reqGet(`${apiBaseUrl}/dashboard-stats/`, params),
  getUsers: async (params) => await reqGet(`${apiBaseUrl}/users/`, params),
  getNonPagedUsers: async (params) => await reqGet(`${apiBaseUrl}/users/no-page`, params),
  createUsers: async (data) => await reqPost(`${apiBaseUrl}/users/`, data),
  getTheme: async () => await reqGet(`${apiBaseUrl}/users/get-theme/`),
  deleteTheme: async () => await reqDelete(`${apiBaseUrl}/users/remove-theme/`),
  updateTheme: async (data) => await reqPost(`${apiBaseUrl}/users/update-theme/`, data),
  createStudentUserAccount: async (params) =>
    await reqPost(`${apiBaseUrl}/students/create-student-user-account/`, params),
  getStudents: async (params) => await reqGet(`${apiBaseUrl}/students/`, params),
  deleteStudent: async (id) => await reqDelete(`${apiBaseUrl}/students/${id}/`),
  getStudentDetails: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/`),
  getStudentDetailedObject: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/details/`),
  getStudentDynamicField: async () =>
    await reqGet(`${apiBaseUrl}/student-dynamic-fields/fields-list/`),
  getStudentDynamicFieldById: async (id) =>
    await reqGet(`${apiBaseUrl}/student-dynamic-fields/${id}`),
  addDynamicField: async (data) => await reqPost(`${apiBaseUrl}/student-dynamic-fields/`, data),
  updateDynamicField: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/student-dynamic-fields/${id}/`, data),
  deleteStudentDynamicField: async (id) =>
    await reqDelete(`${apiBaseUrl}/student-dynamic-fields/${id}/`),
  getStudentDraft: async (id) => await reqGet(`${apiBaseUrl}/draft-student/${id}/`),
  getStudentDrafts: async (params) => await reqGet(`${apiBaseUrl}/draft-student/list/`, params),
  deleteDraftStudent: async (id) => await reqDelete(`${apiBaseUrl}/draft-student/${id}/`),
  addStudentDraft: async (data) => await reqAnonPost(`${apiBaseUrl}/draft-student/`, data),
  updateStudentDraft: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/draft-student/${id}/`, data),
  enrollStudent: async (data) => await reqPost(`${apiBaseUrl}/draft-student/enroll/`, data),
  getStudentData: async (params) =>
    await reqGet(`${apiBaseUrl}/students/get-students-data/`, params),
  getStudentFeeDetails: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/fee/`),
  getPreviousYearFee: async (id) =>
    await reqGet(`${apiBaseUrl}/students/${id}/previous-year-fees/`),
  updateStudentFee: async (id, data) => await reqPost(`${apiBaseUrl}/students/${id}/fee/`, data),
  createStudentCustomFee: async (data) =>
    await reqPost(`${apiBaseUrl}/student-chargeable-fee/`, data),
  getStudentCustomFees: async (params) =>
    await reqGet(`${apiBaseUrl}/student-chargeable-fee/`, params),
  getCustomFees: async (params) =>
    await reqGet(`${apiBaseUrl}/student-chargeable-fee/fetch-fee-details`, params),
  updateStudentCustomFee: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/student-chargeable-fee/${id}/`, data),
  deleteStudentCustomFee: async (id) =>
    await reqDelete(`${apiBaseUrl}/student-chargeable-fee/${id}/`),
  createFeeTag: async (data) => await reqPost(`${apiBaseUrl}/fee-tags/`, data),
  getFeeTags: async (params) => await reqGet(`${apiBaseUrl}/fee-tags/`, params),
  getFeeTag: async (id) => await reqGet(`${apiBaseUrl}/fee-tags/${id}`),
  updateFeeTag: async (id, data) => await reqPatch(`${apiBaseUrl}/fee-tags/${id}/`, data),
  deleteFeeTag: async (id) => await reqDelete(`${apiBaseUrl}/fee-tags/${id}/`),
  getPreviousYearFees: async (params) => await reqGet(`${apiBaseUrl}/previous-year-fees/`, params),
  getPreviousYearFeesDetails: async (id) => await reqGet(`${apiBaseUrl}/previous-year-fees/${id}/`),
  addPreviousYearFees: async (data) => await reqPost(`${apiBaseUrl}/previous-year-fees/`, data),
  editPreviousYearFees: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/previous-year-fees/${id}/`, data),
  deletePreviousYearFees: async (id) => await reqDelete(`${apiBaseUrl}/previous-year-fees/${id}/`),
  getStudentCustomFee: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/applicable-fees/`),
  getStudentsImportSummaries: async (params) =>
    await reqGet(`${apiBaseUrl}/students-import-summary/`, params),
  getStudentsImportSummaryDetail: async (id) =>
    await reqGet(`${apiBaseUrl}/students-import-summary/${id}/`),
  getRecentlyAddedStudents: async (params) =>
    await reqGet(`${apiBaseUrl}/students/recently-added/`, params),
  createStudents: async (data) => await reqPost(`${apiBaseUrl}/students/`, data),
  getStudentsStats: async (params) => await reqGet(`${apiBaseUrl}/students/stats/`, params),
  generateIdCards: async (params) =>
    await reqGet(`${apiBaseUrl}/students/generate-id-cards`, params),
  getAdmissionForm: async (id) =>
    await reqGet(`${apiBaseUrl}/students/generate-admission-form/?ids=${id}`),
  getGrades: async () => await reqGet(`${apiBaseUrl}/grades/`),
  getGrade: async (id) => await reqGet(`${apiBaseUrl}/grades/${id}/`),
  createGrade: async (data) => await reqPost(`${apiBaseUrl}/grades/`, data),
  updateGrade: async (id, data) => await reqPatch(`${apiBaseUrl}/grades/${id}/`, data),
  getGradesQuickList: async () => await reqGet(`${apiBaseUrl}/grades/quick-list/`),
  getGradesSection: async (id) => await reqGet(`${apiBaseUrl}/grade-sections/${id}/`),
  generateRollNumber: async (id) =>
    await reqPost(`${apiBaseUrl}/grades/${id}/initialize-roll-number/`),
  updateGradesSection: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/grade-sections/${id}/`, data),
  saveGradesSectionAttendance: async (id, data) =>
    await reqPost(`${apiBaseUrl}/grade-sections/${id}/save-attendance/`, data),
  getGradesSectionAllStudents: async (id) =>
    await reqGet(`${apiBaseUrl}/grade-sections/${id}/all-students/`),
  generateRasa: async (id, params) =>
    await reqGet(`${apiBaseUrl}/grade-sections/${id}/generate-rasa/`, params),
  generateAdmitCard: async (id, params) =>
    await reqGet(`${apiBaseUrl}/grade-sections/${id}/generate-admit-card/`, params),
  getGradesSectionsList: async () => await reqGet(`${apiBaseUrl}/grades/sections-list/`),
  getAttendance: async (params) => await reqGet(`${apiBaseUrl}/attendance/`, params),
  getAttandanceByDate: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/all-attendance-by-date/`, params),
  getDayToDayAttandance: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/analytics-grade-per-day`, params),
  getAttendanceStats: async (params) => await reqGet(`${apiBaseUrl}/attendance/stats/`, params),
  attendanceReport: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/grade-analytics/`, params),
  getDateRangeAttendance: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/in-date-range/`, params),
  getGradesSectionAttendances: async (params) => await reqGet(`${apiBaseUrl}/attendance/`, params),
  getGradesSectionAttendanceDetails: async (id, params) =>
    await reqGet(`${apiBaseUrl}/attendance/${id}/`, params),
  updatedStudentMarkedAttendance: async (id, data) =>
    await reqPost(`${apiBaseUrl}/attendance/${id}/update/`, data),
  uploadAttendanceData: async (data) =>
    await reqPost(`${apiBaseUrl}/attendance/upload-student-attendance/`, data),
  getTransactions: async (params) => await reqGet(`${apiBaseUrl}/transactions/`, params),
  getTransactionsStats: async (params) => await reqGet(`${apiBaseUrl}/transactions/stats/`, params),
  getTransactionDetails: async (id, params) =>
    await reqGet(`${apiBaseUrl}/transactions/${id}/`, params),
  searchGlobally: async (params) => await reqGet(`${apiBaseUrl}/global-search/`, params),
  uploadStudents: async (data) =>
    await reqPost(`${apiBaseUrl}/students/import/`, data, {
      ...getAuthHeaders(),
      "Content-Type": "multipart/form-data",
    }),
  uploadUsers: async (data) =>
    await reqPost(`${apiBaseUrl}/users/import/`, data, {
      ...getAuthHeaders(),
      "Content-Type": "multipart/form-data",
    }),
  getUserDetails: async (id) => await reqGet(`${apiBaseUrl}/users/${id}/details`),
  updatePermissions: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/users/${id}/update-permissions/`, data),

  updateUserDetails: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/users/${id}/update-details/`, data),
  addTransaction: async (data) => await reqPost(`${apiBaseUrl}/transactions/`, data),
  getTransaction: async (id, params) => await reqGet(`${apiBaseUrl}/transactions/${id}`, params),
  updateTransaction: async (id, data) => await reqPatch(`${apiBaseUrl}/transactions/${id}/`, data),
  addFinanceAccount: async (data) => await reqPost(`${apiBaseUrl}/finance-accounts/`, data),
  getAllFinanceAccounts: async (params) => await reqGet(`${apiBaseUrl}/finance-accounts/`, params),

  getFinanceAccount: async (id, params) =>
    await reqGet(`${apiBaseUrl}/finance-accounts/${id}/`, params),
  updateFinanceAccount: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/finance-accounts/${id}/`, data),
  addExamination: async (data) => await reqPost(`${apiBaseUrl}/examinations/`, data),
  updateExamination: async (id, data) => await reqPatch(`${apiBaseUrl}/examinations/${id}/`, data),
  getExaminations: async (params) => await reqGet(`${apiBaseUrl}/examinations/`, params),
  deleteExamination: async (id) => await reqDelete(`${apiBaseUrl}/examinations/${id}/`),
  getExaminationDetails: async (id, params) =>
    await reqGet(`${apiBaseUrl}/examinations/${id}/`, params),
  getSubjects: async (params) => await reqGet(`${apiBaseUrl}/subjects/`, params),
  addSubjects: async (data) => await reqPost(`${apiBaseUrl}/subjects/`, data),
  getSubjectDetails: async (id) => await reqGet(`${apiBaseUrl}/subjects/${id}/`),
  updateSubjects: async (id, data) => await reqPatch(`${apiBaseUrl}/subjects/${id}/`, data),

  deleteSubject: async (subject_id) => {
    await reqDelete(`${apiBaseUrl}/subjects/${subject_id}/`);
  },

  addExamSchedule: async (data) => await reqPost(`${apiBaseUrl}/exam-schedules/`, data),
  getExamSchedule: async (params) => await reqGet(`${apiBaseUrl}/exam-schedules/`, params),
  getExamScheduleDetails: async (id) => await reqGet(`${apiBaseUrl}/exam-schedules/${id}/`),
  updateExamSchedule: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/exam-schedules/${id}/`, data),
  deleteExamSchedule: async (id) => await reqDelete(`${apiBaseUrl}/exam-schedules/${id}/`),
  uploadExamResults: async (id, data) =>
    await reqPost(`${apiBaseUrl}/examinations/${id}/update-results/`, data, {
      ...getAuthHeaders(),
      "Content-Type": "multipart/form-data",
    }),

  createQuestionPaper: async (data) => await reqPost(`${apiBaseUrl}/question-paper/`, data),
  getQuestionPapers: async (params = {}) => await reqGet(`${apiBaseUrl}/question-paper/`, params),
  getQuestionPaperDetails: async (id, params = {}) =>
    await reqGet(`${apiBaseUrl}/question-paper/${id}/`, params),
  editQuestionPaper: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/question-paper/${id}/`, data),
  updateExamPaper: async (data) =>
    await reqPatch(`${apiBaseUrl}/question-paper/retrieve-question-paper/`, data),
  deleteQuestionPaper: async (id) => {
    await reqDelete(`${apiBaseUrl}/question-paper/${id}/delete/`);
  },
  getSubjectChapters: async (params = {}) =>
    await reqGet(`${apiBaseUrl}/question-paper/get-subject-chapters/`, params),
  generateQuestionPaper: async (data) =>
    await reqPost(`${apiBaseUrl}/question-paper/generate-question-paper/`, data),
  refreshQuestion: async (id, params) =>
    await reqGet(`${apiBaseUrl}/question-paper/${id}/refresh-question/`, params),
  searchQuestions: async (params = {}) =>
    await reqGet(`${apiBaseUrl}/question-paper/search-question/`, params),
  addQuestion: async (id, data) =>
    await reqPost(`${apiBaseUrl}/question-paper/${id}/add-random-question/`, data),
  updateInitialExamReceipe: async (data) =>
    await reqPost(`${apiBaseUrl}/question-paper/update-initial-exam-receipe/`, data),
  generateExamPaperReceipe: async (params = {}) =>
    await reqGet(`${apiBaseUrl}/question-paper/generate-exam-paper-docx/`, params),

  getDraftResults: async (params) => await reqGet(`${apiBaseUrl}/draft-results/`, params),

  deleteDraftResult: async (id) => await reqDelete(`${apiBaseUrl}/draft-results/${id}/`),

  getDraftResultsDetails: async (id) => await reqGet(`${apiBaseUrl}/draft-results/${id}`),

  getStudentUsingEnrollmentID: async (params) => await reqGet(`${apiBaseUrl}/students/`, params),

  getAllStudents: async () => await reqGet(`${apiBaseUrl}/students/all/`),

  getExamScheduleUsingGradeSection: async (params) =>
    await reqGet(`${apiBaseUrl}/exam-schedules/`, params),

  verifyStudentResult: async (examId, data) =>
    await reqPost(`${apiBaseUrl}/examinations/${examId}/approve-student-draft/`, data),

  getResults: async (params) => await reqGet(`${apiBaseUrl}/student-results/`, params),
  getResultsDetails: async (id) => await reqGet(`${apiBaseUrl}/student-results/${id}/`),
  updateResultsDetails: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/student-results/${id}/`, data),

  printResults: async (id) => await reqGet(`${apiBaseUrl}/student-results/${id}/print-result/`),
  syncStudentResult: async (id) =>
    await reqPost(`${apiBaseUrl}/examinations/${id}/sync-missing-students-results/`),
  printAllResults: async (ids) =>
    await reqPost(`${apiBaseUrl}/student-results/print-results/`, ids),

  getSalaryDetails: async (id) => await reqGet(`${apiBaseUrl}/users/${id}/salary/`),

  paySalary: async (id, data) => await reqPost(`${apiBaseUrl}/users/${id}/salary/`, data),

  createDynamicFields: async (data) =>
    await reqPost(`${apiBaseUrl}/tenants/generate-dynamic-field-template/`, data),
  createTenant: async (data) => await reqPost(`${apiBaseUrl}/tenants/create-tenant/`, data),
  getTenants: async () => await reqGet(`${apiBaseUrl}/tenants/`),
  createOrUpdateFCMToken: async (data) =>
    await reqPost(`${apiBaseUrl}/push-notifications/token/`, data),
  getFCMToken: async (params) => await reqGet(`${apiBaseUrl}/push-notifications/token/`, params),
  deleteFCMToken: async (data) =>
    await reqPost(`${apiBaseUrl}/push-notifications/delete-token/`, data),
  updateExamFee: async (id, data) => await reqPost(`${apiBaseUrl}/students/${id}/exam-fee/`, data),

  getExamFee: async (id, data) => await reqGet(`${apiBaseUrl}/students/${id}/exam-fee/`, data),

  updateAdmissionFee: async (id, data) =>
    await reqPost(`${apiBaseUrl}/students/${id}/admission-fee/`, data),

  getAdmissionFee: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/admission-fee/`),

  updateCustomFees: async (id, data) =>
    await reqPost(`${apiBaseUrl}/students/${id}/chargeable-fees/`, data),

  exportStudents: async (params) => await reqGet(`${apiBaseUrl}/students/export-csv/`, params),

  BulkUpdateStudents: async (data) => await reqPatch(`${apiBaseUrl}/students/bulk-update/`, data),

  updateStudents: async (id, data) => await reqPatch(`${apiBaseUrl}/students/${id}/`, data),

  updateStudentDetails: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/student-details/${id}/`, data),

  getUnPaidExams: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/get-unpaid-exam-fees/`),
  getUnPaidTutionFeeStudents: async (id) =>
    await reqGet(`${apiBaseUrl}/students/${id}/get-unpaid-tution-fees/`),

  getAllPaidFees: async (id, params) =>
    await reqGet(`${apiBaseUrl}/transactions/${id}/student-fees/`, params),

  getAllStudentFee: async (params) =>
    await reqGet(`${apiBaseUrl}/transactions/all-student-fees/`, params),

  getBalanceSheet: async (params) =>
    await reqGet(`${apiBaseUrl}/finance-accounts/balance-sheet/`, params),

  getAllStudentsFeeDetails: async () =>
    await reqGet(`${apiBaseUrl}/students/get-all-fees-details/`),

  getFinanceChartData: async () => await reqGet(`${apiBaseUrl}/transactions/get-chart-data/`),

  getSchoolSettings: async () => await reqGet(`${apiBaseUrl}/school-settings/`),
  getSchoolSetting: async (id) => await reqGet(`${apiBaseUrl}/school-settings/${id}/`),

  updateSchoolSettings: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/school-settings/${id}/`, data),

  updateTenant: async (id, data) => await reqPatch(`${apiBaseUrl}/tenants/${id}/`, data),

  getAssoassociatedPersonEntity: async () =>
    await reqGet(`${apiBaseUrl}/transactions/get-all-associated-person-or-entity/`),

  getLiveResultExam: async () => await reqGet(`${apiBaseUrl}/examinations/get-live-result-exam/`),
  findLiveResultStudent: async (params) =>
    await reqGet(`${apiBaseUrl}/examinations/find-public-results/`, params),
  getLiveResult: async (params) =>
    await reqGet(`${apiBaseUrl}/examinations/get-live-result-exam/`, params),

  getAnnouncements: async () => await reqGet(`${apiBaseUrl}/announcements/`),
  getAnnouncement: async (id) => await reqGet(`${apiBaseUrl}/announcements/${id}/`),

  addAnnouncement: async (data) => await reqPost(`${apiBaseUrl}/announcements/`, data),
  getPublicAnnouncements: async () =>
    await reqGet(`${apiBaseUrl}/announcements/get-recent-public-announcements/`),
  deleteAnnouncement: async (announcement_id) =>
    await reqDelete(`${apiBaseUrl}/announcements/${announcement_id}/delete/`),

  updateAnnouncement: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/announcements/${id}/`, data),

  addAdmission: async (data) => await reqPost(`${apiBaseUrl}/admission-query/`, data),
  getAdmissionDetails: async () => await reqGet(`${apiBaseUrl}/admission-query/`),

  socialMediaPost: async (data) => await reqPost(`${apiBaseUrl}/social-media-post/`, data),
  getPosts: async () => await reqGet(`${apiBaseUrl}/public-posts/`),
  getPost: async (id) => await reqGet(`${apiBaseUrl}/public-posts/${id}/`),
  addPost: async (data) => await reqPost(`${apiBaseUrl}/public-posts/`, data),
  updatePost: async (id, data) => await reqPatch(`${apiBaseUrl}/public-posts/${id}/`, data),
  getPublicPosts: async () => await reqGet(`${apiBaseUrl}/public-post/`),
  getPublicPost: async (id) => await reqGet(`${apiBaseUrl}/public-post/${id}/`),
  deletePost: async (id) => await reqDelete(`${apiBaseUrl}/public-posts/${id}/delete/`),

  addStorageFile: async (data) => await reqPost(`${apiBaseUrl}/storage-files/upload/`, data),
  deleteStorageFile: async (id) =>
    await reqDelete(`${apiBaseUrl}/storage-files/${id}/delete_file/`),
  getStorageFiles: async () => await reqGet(`${apiBaseUrl}/storage-files/`),
  getStorageFolders: async () => await reqGet(`${apiBaseUrl}/storage-folders/`),

  getPublicWebsites: async () => await reqGet(`${apiBaseUrl}/public-websites/`),
  getPublicWebsite: async () => await reqGet(`${apiBaseUrl}/public-website/`),
  updatePublicWebsite: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/public-websites/${id}/`, data),
  addPublicWebsite: async (data) => await reqPost(`${apiBaseUrl}/public-websites/`, data),
  getNotifications: async (params = {}) => await reqGet(`${apiBaseUrl}/notifications/`, params),
  updateNotificationsReadTime: async (data = {}) =>
    await reqPost(`${apiBaseUrl}/notifications/update-my-checked-at/`, data),
  testUnitFeature: async (featureName, ids) =>
    await reqPost(`${apiBaseUrl}/test-unit-feature/${featureName}/`, ids),
  getPaymentReceipt: async (id, params) =>
    await reqGet(`${apiBaseUrl}/transactions/${id}/get-receipt/`, params),
  getEventList: async () => await reqGet(`${apiBaseUrl}/calendar/list-upcoming-events/`),
  getEvents: async (params) => await reqGet(`${apiBaseUrl}/calendar/`, params),
  getEvent: async (id) => await reqGet(`${apiBaseUrl}/calendar/${id}/`),
  updateEvent: async (id, data) => await reqPatch(`${apiBaseUrl}/calendar/${id}/`, data),
  deleteEvent: async (id) => await reqDelete(`${apiBaseUrl}/calendar/${id}/`),
  createEvent: async (data) => await reqPost(`${apiBaseUrl}/calendar/`, data),
  getAlertMessages: async (params) => await reqGet(`${apiBaseUrl}/action-item/`, params),
  getActionRequests: async (params) => await reqGet(`${apiBaseUrl}/action-request/`, params),
  updateRequestStatus: async (id, params) =>
    await reqPatch(`${apiBaseUrl}/action-request/${id}/`, params),
  getActivityLogs: async (params) => await reqGet(`${apiBaseUrl}/activity-log/`, params),
  getTeacherDraft: async (id) => await reqGet(`${apiBaseUrl}/draft-teacher/${id}/`),
  getTeacherDrafts: async (params) => await reqGet(`${apiBaseUrl}/draft-teacher/list/`, params),
  addTeacherDraft: async (data) => await reqAnonPost(`${apiBaseUrl}/draft-teacher/`, data),
  updateTeacherDraft: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/draft-teacher/${id}/`, data),
  getAllStudentsFeeDetailsYearlyType: async () =>
    await reqGet(`${apiBaseUrl}/students/get-all-fees-details-yearly-type/`),
  enrollTeacher: async (data) => await reqPost(`${apiBaseUrl}/draft-teacher/enroll/`, data),
  deleteDraftTeacher: async (id) => await reqDelete(`${apiBaseUrl}/draft-teacher/${id}/`),

  getCourses: async (params) => await reqGet(`${apiBaseUrl}/course/`, params),
  getCoursesByGradeSection: async (params) =>
    await reqGet(`${apiBaseUrl}/course/by-grade-section/`, params),
  getChapters: async (params) => await reqGet(`${apiBaseUrl}/chapters/`, params),
  getFullChapter: async (id, params) => await reqGet(`${apiBaseUrl}/chapters/${id}/`, params),
  updateCourseStatusProgess: async (data) =>
    await reqPost(`${apiBaseUrl}/chapters/enrollment-course-progress-update/`, data),
  getEnrolledCourses: async (params) => await reqGet(`${apiBaseUrl}/enrollment-courses/`, params),
  appGetEnrolledCourses: async (params) =>
    await reqGet(`${apiBaseUrl}/app-enrollment-courses/`, params),
  enrolledCourse: async (data) => await reqPost(`${apiBaseUrl}/app-enrollment-courses/`, data),

  getAdminSummaryDetails: async (params) =>
    await reqGet(`${apiBaseUrl}/tenants/admin-summary-by-date/`, params),
  createSocialMediaAccount: async (data) =>
    await reqPost(`${apiBaseUrl}/connected-social-accounts/create-social-account/`, data),
  getConnectedSocialAccounts: async (params) =>
    await reqGet(`${apiBaseUrl}/connected-social-accounts/`, params),
  deleteConnectedSocialAccount: async (id, params) =>
    await reqDelete(`${apiBaseUrl}/connected-social-accounts/${id}/`, params),
  schoolAssistant: async (data) =>
    await reqPost(`${apiBaseUrl}/assistant/`, data),
};

export default apiCalls;
